<!-- =========================================================================================
	File Name: Login.vue
	Description: Login Page
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                style="padding-left: 15px;"
                src="@/assets/images/pages/logo.png"
                alt=""
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white"
            >
              <div class="p-12">
                <div class="vx-card__title mb-6">
                  <h1 class="mb-2">Bienvenue sur votre espace intermédiaire</h1>
                </div>
                <div v-show="error" class="mb-4 text-danger">
                  {{ errorMsg }}
                </div>
                <div>
                  <div class="clearfix">
                    <vs-input
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      type="text"
                      name="nom d'utilisateur"
                      icon="icon icon-user"
                      icon-pack="feather"
                      label="Nom d'utilisateur"
                      v-model="form.username"
                      class="w-full no-icon-border"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("nom d'utilisateur")
                    }}</span>

                    <vs-input
                      v-validate="'required|min:8'"
                      data-vv-validate-on="blur"
                      type="password"
                      name="mot de passe"
                      icon="icon icon-unlock"
                      icon-pack="feather"
                      label="Mot de passe"
                      v-model="form.password"
                      class="w-full mt-2 no-icon-border"
                    />

                    <span class="text-danger text-sm">{{
                      errors.first("mot de passe")
                    }}</span>

                    <div class="mt-8">
                      <div class="inline-flex">
                        <div>
                          <vs-button
                            @click="loginUser"
                            :disabled="!validateForm || loading"
                            >Connexion</vs-button
                          >
                        </div>
                        <div class="ml-2">
                          <clip-loader
                            :loading="loading"
                            color="primary"
                          ></clip-loader>
                        </div>
                      </div>
                      <router-link
                        class="float-right mt-2"
                        :to="{ name: 'ForgetPassword' }"
                        >Mot de passe oublié ?</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      error: false,
      errorMsg: "",
      form: {
        username: "",
        password: ""
      }
    };
  },
  created() {
    //add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
  },
  mounted() {
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.form.username != "" &&
        this.form.password != ""
      );
    }
  },
  methods: {
    ...mapActions("auth", [
      "logout",
      "loginAccount",
      "verified",
    ]),
    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    async loginUser() {
      if (!this.validateForm) return false;

      this.loading = true;
      try {
        let res = await this.loginAccount(this.form);
        this.loading = false;
        this.error = false;

        if (res === "typeError") {
          this.acceptAlert(
            "danger",
            "Authentification",
            "Vous n'etes pas autorisé(e)"
          );
          this.error = true;
        } else {
          this.verified();
          setTimeout(() => {
            this.$router.push(this.$route.query.redirect || { name: "Home" });
          }, 500);
        }
      } catch (err) {
        this.loading = false;
        this.error = true;

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userAccountDeactivate":
                  this.errorMsg = item.message;
                  break;
                case "userAccountWaiting":
                  this.errorMsg = item.message;
                  break;
                case "userNotFound":
                  this.errorMsg = "Nom d'utilisateur ou mot de passe incorrect";
                  break;
                case "userPasswordNotCorrect":
                  this.errorMsg = "Nom d'utilisateur ou mot de passe incorrect";
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Connexion",
                    "Une erreur lors de la connexion"
                  );
                  break;
              }
            });
          }
        }
      }
    },
  },
  components: {
    ClipLoader
  }
};
</script>
<style lang="scss">
</style>
